.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main > section:nth-child(n + 2) {
  margin-top: 20px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-clip: border-box;
  min-height: 80vh;
  width: 100%;
}

.personal {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.personal > * {
  margin: 0px 20px;
  width: fit-content;
}

.picture {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.logo {
  width: 50px;
  height: 50px;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 80px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.name {
  font-size: 3rem;
}

.job {
  font-size: 1.5rem;
  font-weight: 100;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  width: fit-content;
  padding: 0px;
  margin: 0px;
  margin-top: 20px;
}

.social li:hover {
  transform: scale(1.1);
}

.social .item {
  transition: transform 0.4s;
  padding: 5px;
  margin: 0px 8px;
}

.social .item span {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content > * {
  margin: 5px 0px;
}

.shortcut {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  list-style-type: none;
}

.item {
  padding: 10px;
  margin: 0px 10px;
  border-radius: 20px;
  transition: transform 0.4s;
}

.shortcut .item {
  transition: transform 0.4s;
  padding: 5px;
  margin: 0px 8px;
}

.item > * {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item:hover {
  transform: scale(1.05);
}

.item span {
  font-size: 1.6rem;
  height: fit-content;
  margin-left: 20px;
  font-weight: 600;
}

.tabs {
  display: flex;
  list-style-type: none;
  flex-direction: row;
}

.tabs > * {
  margin: 0px 10px;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.scrolldown {
  position: absolute;
  bottom: 10px;
}

@media (max-width: 480px) {
  .shortcut li {
    flex: 0 40%;
  }
  .social li {
    flex: 0 30%;
  }
}

@media (max-width: 1080px) {
  .data {
    margin: 0px;
  }

  .picture {
    align-self: center;
  }

  .personal {
    flex-direction: column;
    margin-top: 20px;
  }

  .job {
    padding: 0px 10px;
  }

  .social {
    margin: 10px 20px;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .info {
    margin-top: 50px;
    text-align: center;
  }

  .shortcut {
    padding: 0px;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;
  }

  .shortcut .item {
    display: flex;
    align-self: first baseline;
  }

  .social .item {
    align-self: first baseline;
  }

  .social .item span {
    display: block;
  }
}
