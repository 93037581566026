:root {
  --primary: #000624;
  --secondary: #dc9930;
}

html,
body {
  color: white;
  background-color: var(--primary);
  margin: 0;
  padding: 0;
  font-family: "Recursive", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 10px;
}

* {
  box-sizing: border-box;
}
