.carousel {
  display: flex;
  flex-direction: row;
  flex-basis: 30%;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0px;
}

.title {
  margin-bottom: 10px;
}
