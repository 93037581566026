.card {
  height: fit-content;
  display: flex;
  flex: 1 30%;
  flex-direction: row;
  margin: 10px;
  user-select: none;
  -webkit-user-drag: none;
}

.card article {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 300px;
  border-radius: 20px;
  background-color: black;
  transition: transform 0.5s;
}

.card article:hover {
  transform: translateY(-8px);
}

.card article::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: rgba(16, 17, 30, 0.6);
  pointer-events: none;
}

.title {
  padding: 10px 20px;
  z-index: 3;
  margin-top: auto;
  position: relative;
  color: white;
  font-weight: 800;
}

.tags + .title {
  padding-bottom: 0px;
}

.date {
  z-index: 3;
  padding: 20px;
  font-weight: 600;
  align-self: flex-end;
}

.cover {
  position: absolute;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
  user-select: none;
  -webkit-user-drag: none;
}

.tags {
  display: flex;
  flex-direction: row;
  z-index: 3;
  margin: 0px 10px 10px;
  flex-wrap: wrap;
}

.tags .item {
  background-color: var(--secondary);
  color: var(--primary);
  padding: 5px;
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: 8px;
  margin: 5px;
  font-weight: 600;
}

@media screen and (max-width: 720px) {
  .card {
    flex: 0 100%;
  }
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .card {
    flex: 0 45%;
  }
}
